const ContactConfig = {
    ignore: [],
    errorPlacement: function (error, element) {
        error.insertAfter(element);
    },
    messages: {
        'phonenumber-contact': {
            minlength: 'Phone number has invalid length'
        }
    },
    rules: {
        'name-contact': {
            required: true,
        },
        'email-contact': {
            required: true,
            email: true
        },
        'phonenumber-contact': {
            required: true,
            minlength: 10,
            maxlength: 10
        },
        'message': {
            required: true,
        }
    }
};

const CallMeConfig = {
    ignore: [],
    errorPlacement: function (error, element) {
        error.insertAfter(element);
    },
    messages: {
        'phonenumber-contact': {
            minlength: 'Phone number has invalid length'
        }
    },
    rules: {
        'name': {
            required: true,
        },
        'email': {
            required: true,
            email: true
        },
        'phonenumber': {
            required: true,
            minlength: 10,
            maxlength: 10
        }
    }
};

export { ContactConfig, CallMeConfig };