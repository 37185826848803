require('bootstrap');
import $ from 'jquery';
require('jquery-validation');
require('jquery-mask-plugin');
import { CallMeConfig, ContactConfig } from'./validation/config';

$(function () {

    // jQuery validation configuration
    $('#contact-form').validate(ContactConfig);
    $('#callme-form').validate(CallMeConfig);
    $('input[type="tel"]').mask('0000000000');

    // On submit Contact form
    $('.contact.btn-submit').on('click', function (e) {
        if ($('#contact-form').valid()) {
            var formData = $('#contact-form').serialize();
            $.ajax({
                type: 'POST',
                url: "/general-send-info.php",
                data: formData
            })

            .done(function(response) {
                $('#contact-form').validate().resetForm();
                window.location.href="/thank-you.html";
            })

        }
    });

    // On submit Call Me form
    $('.callme.btn-submit').on('click', function (e) {
        if ($('#callme-form').valid()) {
           
                var formData = $('#callme-form').serialize();
                $.ajax({
                    type: 'POST',
                    url: "/general-send-info.php",
                    data: formData
                })

                .done(function(response) {
                    $('#callme-form').validate().resetForm();
                    window.location.href="/thank-you.html";
                })
               
        }
    });

    // Reset Call Me form on Modal open
    $('#callMeModal').on('show.bs.modal', function (e) {
        $('#callme-form').trigger("reset");
        $('#callme-form').validate().resetForm();
    });
    // Reset Contact form on Modal open
    $('#contactUsModal').on('show.bs.modal', function (e) {
        $('#contact-form').trigger("reset");
        $('#contact-form').validate().resetForm();
    });
});